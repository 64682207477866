import React, { useState, useEffect } from 'react'
import { ApiEndPoint } from '../../Components/GlobalData/GlobalData'
import Axios from 'axios'
import Loading from '../../Components/Loading/Loader'
function HotelReamrk () {
  var endpoint = ApiEndPoint()
  const [isLoading, setIsLoading] = useState(true)
  const [actualReservationRes, setActualReservationRes] = useState([])
  useEffect(() => {
    getinvoicedetail()
  }, [])
  const getinvoicedetail = async () => {
    const currentUrl = window.location.pathname
    const lastPart = currentUrl.substring(currentUrl.lastIndexOf('/') + 1)

    var invoiceno = {
      invoice_no: lastPart
    }
    // _Live
    try {
      const response = await Axios.post(
        endpoint + '/api/hotels/view/reservation',
        invoiceno,
        {
          headers: {
            'Access-Control-Allow-Origin': '*',
            // Required for CORS support to work
            'Access-Control-Allow-Credentials': true, // Required for cookies, authorization headers with HTTPS
            'Access-Control-Allow-Headers':
              'Origin,Content-Type,X-Amz-Date,Authorization,X-Api-Key,X-Amz-Security-Token,locale'
            // "Access-Control-Allow-Methods": "POST, OPTIONS"
          }
        }
      )
      if (response.length !== '') {
        setIsLoading(false)
        var actualdata = JSON.parse(
          response.data.booking_data.actual_reservation_response
        )
        setActualReservationRes(actualdata)
      }
      setIsLoading(false)
    } catch (error) {
      // Handle errors here
      setIsLoading(false)
      console.error('Error:', error)
    }
  }
  return (
    <>
      {isLoading && <Loading />}
      <div className='container'>
        <div class='container'>
          <img
            style={{ width: '100%' }}
            src='https://system.alhijaztours.net/public/admin_package/frontend/images/vochure-header.png'
            alt='letterhead'
          />
        </div>
        <div class='row'>
            <div className='text-end'>
             <button onClick={()=>window.print()} className='btn btn-success'>Print Remarks</button>
            </div>
          <h4>Booking Remrks</h4>
          <div
            dangerouslySetInnerHTML={{
              __html: actualReservationRes.Remark
            }}
          />
        </div>
        <div class="container">
            <img src="https://system.alhijaztours.net/public/admin_package/frontend/images/vochure-footer.png" alt="letterhead" />
        </div>
      </div>
    </>
  )
}

export default HotelReamrk
